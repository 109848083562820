import { createSlice } from "@reduxjs/toolkit";

const ModelReducer = createSlice({
  name: "modelController",
  initialState: { showModel: false },
  reducers: {
    openModel: (state) => {
      state.showModel = true;
    },
    closeModel: (state) => {
      state.showModel = false;
    },
  },
});

export const { openModel, closeModel } = ModelReducer.actions;
export default ModelReducer.reducer;
