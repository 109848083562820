import { createSlice } from "@reduxjs/toolkit";

const ModelReducer = createSlice({
  name: "fileController",
  initialState: {
    isImage: false,
    isFile: false,
    imageFile: null,
    pdfFile: null,
  },
  reducers: {
    setImageFile: (state, action) => {
      return { ...state, isImage: true, imageFile: action.payload };
    },
    resetImageFile: (state, action) => {
      return { ...state, isImage: false, imageFile: action.payload };
    },
    setPdfFile: (state, action) => {
      console.log(action.payload);
      return { ...state, isFile: true, pdfFile: action.payload };
    },
    resetPdfFile: (state, action) => {
      return { ...state, isFile: false, pdfFile: action.payload };
    },
  },
});

export const { setImageFile, resetImageFile, setPdfFile, resetPdfFile } =
  ModelReducer.actions;
export default ModelReducer.reducer;
