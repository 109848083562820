// WhatsAppStickyButton.js

import React from 'react';
import { Link } from 'react-router-dom';
import Whatsapp from "../../assets/images/whatsapp-icon.png"

const WhatsAppStickyButton = () => {
  const phoneNumber = '+917397784467'; // Replace with your WhatsApp number

  const handleHover = () => {
    // Add any additional logic for hover effects if needed
  };

  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#25d366', // WhatsApp green color
    padding: '10px',
    borderRadius: '50%',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    transition: 'background-color 0.3s ease-in-out, transform 0.3s ease-in-out',
  };

  const imgStyle = {
    width: '30px', // Set the width of your WhatsApp icon
    height: 'auto',
    borderRadius: '50%', // Make the image circular
  };

  return (
    <Link
      to={`https://wa.me/${phoneNumber}`}
      target="_blank"
      style={buttonStyle}
      onMouseEnter={handleHover}
    >
      <img src={Whatsapp} alt="WhatsApp Icon" style={imgStyle}  title='Chat With us on Whatsapp click here '/>
    </Link>
  );
};

export default WhatsAppStickyButton;
