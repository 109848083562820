import { configureStore } from "@reduxjs/toolkit";
import ModelReducer from "./modelReducer";
import FileReducer from "./fileReducer";

export default configureStore({
  reducer: {
    ModelController: ModelReducer,
    FileController: FileReducer,
  },
});
