import Main from "./components/main";
import Routers from "./routers";
import "./index.scss";
// import BubbleAnimate from "./components/bubbleAnimate";
import AOS from "aos";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";




function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  

  // const handleContextMenu = (e) => {
  //   e.preventDefault();
  // };

  // useEffect(() => {
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);
  return (
    <div class="radial_gradient_background">
      <BrowserRouter>
        <HelmetProvider>
          <Main>
            {/* <BubbleAnimate /> */}
   
            <Routers />
        
          </Main>
        </HelmetProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
